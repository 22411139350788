// eslint-disable-next-line no-restricted-imports
import { AuthService, OddbooksService } from '@/js/api/client';
import { errorHandler } from '@/js/utils/errorHandler';

type BooksServiceKey = keyof Omit<typeof OddbooksService, 'prototype'>;
type BooksServiceCall = (typeof OddbooksService)[BooksServiceKey];

export type AuthServiceKey = keyof Omit<typeof AuthService, 'prototype'>;
type AuthServiceCall = (typeof AuthService)[AuthServiceKey];

function wrapper<Service, ServiceCall>(
  // eslint-disable-next-line @typescript-eslint/ban-types
  serviceCall: ServiceCall & Function,
  originalThis: Service,
) {
  const wrappedFunction = async (...args: unknown[]) => {
    try {
      const res = await serviceCall.call(originalThis, ...args);
      return res;
    } catch (error: unknown) {
      errorHandler(error);
      throw error;
    }
  };
  return wrappedFunction as ServiceCall;
}

export const BooksService = new Proxy(OddbooksService, {
  get(service, call: BooksServiceKey, receiver: typeof OddbooksService) {
    return wrapper<typeof OddbooksService, BooksServiceCall>(
      service[call],
      receiver,
    );
  },
});

export const BooksAuthService = new Proxy(AuthService, {
  get(service, call: AuthServiceKey, receiver: typeof AuthService) {
    return wrapper<typeof AuthService, AuthServiceCall>(
      service[call],
      receiver,
    );
  },
});

// eslint-disable-next-line no-restricted-imports
export * from '@/js/api/client';
