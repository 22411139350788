<template>
  <li class="list-item" data-listitem="document">
    <component
      :is="isEditing ? 'div' : 'a'"
      data-meta="docinfo"
      data-link="block"
      :href="isEditing ? undefined : `/write/document-${document.id}`"
      @click.prevent="!isEditing && openDocument()"
    >
      <span v-if="isOpen" aria-hidden="true" data-is-open />
      <input
        v-if="isEditing"
        ref="input"
        :value="document.title"
        class="list-item-row item-title"
        required
        :readonly="!connected"
        aria-required="true"
        @change="editTitle"
        @focus="input?.select()"
        @blur="setEditingDocument(null)"
        @keyup.enter="input?.blur()"
        @keyup.esc="editTitleCancel"
      />
      <span v-else class="list-item-row item-title">
        {{ document.title }}
        <span v-if="isOpen" class="visually-hidden">(currently open)</span>
      </span>
      <span class="list-item-row details" data-layout="details">
        <UtilityDate :value="document.text_modified_at" />
        <template v-if="document.public_id !== null">
          | <BooksIcon name="users" size="icon" />
        </template>
      </span>
    </component>
    <UtilityMenu v-if="!isEditing">
      <UtilityMenuButton
        :disabled="!connected"
        @click="setEditingDocument(document.id)"
      >
        Rename
      </UtilityMenuButton>
      <UtilityMenuButton :disabled="!connected" @click="saveAsDocument">
        Save as New Document
      </UtilityMenuButton>
      <UtilityMenuButton
        :disabled="!connected"
        @click="$emit('sharing', document.id)"
      >
        Share
      </UtilityMenuButton>
      <hr />
      <UtilityMenuButton
        :disabled="!connected"
        @click="$emit('archiving', document.id)"
      >
        Archive
      </UtilityMenuButton>
    </UtilityMenu>
  </li>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { computed, defineComponent, type PropType, toRefs } from 'vue';

import type { DocumentRead } from '@/js/api';
import useTitleInput from '@/js/composables/useTitleInput';
import { navigatePanel } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'DocumentListItem',
  props: {
    document: {
      type: Object as PropType<DocumentRead>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    sharing: (docId: number) => true,
    archiving: (docId: number) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  setup(props) {
    const { document, isEditing, connected } = toRefs(props);
    const documentsStore = useDocumentsStore();

    const title = computed<string>(() => document.value.title);

    const saveTitle = (val: string) => {
      if (!connected.value) return;
      documentsStore.updateDocument(document.value.id, {
        title: val,
      });
    };

    const { editTitle, editTitleCancel, input } = useTitleInput({
      isEditing,
      title,
      saveTitle,
    });

    return {
      editTitle,
      editTitleCancel,
      input,
    };
  },
  methods: {
    ...mapActions(useDocumentsStore, ['createDocument', 'setEditingDocument']),
    openDocument() {
      navigatePanel({ to: `document-${this.document.id}` });
    },
    async saveAsDocument() {
      const doc = await this.createDocument({
        parent_id: this.document.id,
        title: `Copy of ${this.document.title}`,
        text: this.document.text,
      });
      /* c8 ignore next */
      if (!doc) return;
      navigatePanel({
        to: `document-${doc.id}`,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'pkg:accoutrement' as tools;

[data-is-open] {
  grid-area: open;
  line-height: 1.2;

  @include tools.before('◆') {
    color: var(--btn);
  }
}

.item-title {
  grid-area: title;
}

.details {
  grid-area: details;
}
</style>
